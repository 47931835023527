<template>
    <div class="contact-box">
        <div class="card">
            <div class="card-title">
                Contact
                <!-- <button class="btn btn-primary" disabled>
                    Add
                </button> -->
            </div>
            <div class="card-body">
                <div class="contact">
                    <!-- <button class="btn btn-primary" disabled>
                        Edit
                    </button> -->
                    <div class="contact-name row">
                        <div class="col-auto">
                            <i class="fas fa-user" />
                            <span> {{ formData.email }}</span>
                        </div>
                    </div>
                    <div class="contact-phone row">
                        <div class="col-auto">
                            <i class="fas fa-phone" />
                            <span>{{ formData.phone }}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="contact">
                    <div class="contact-phone row">
                        <div class="col-auto">
                            <a href="#">Add Phone</a>
                        </div>
                    </div>
                    <div class="contact-phone row">
                        <div class="col-auto">
                            <a href="#">Add Email</a>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContactBox",
    props: {
        formData: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss">
.contact-box {
    .card {
        padding: 0;

        .card-title {
            padding: 10px 25px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            font-family: "Roboto";
            border-bottom: 1px solid #E1E8ED;
            margin-bottom: 0;
        }

        .card-body {
            .contact {
                padding: 15px 25px;
                border-bottom: 1px dashed #E1E8ED;
                position: relative;

                .row {
                    padding: 7px 0;

                    i {
                        margin-right: 10px;
                    }

                    a {
                        color: var(--secondary-color);
                        text-decoration: none;
                    }
                }

                .btn {
                    position: absolute;
                    right: 25px;
                    margin-top: 10px;
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
}
</style>
